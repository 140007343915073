
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Footer(props) {
    return (
        <div>
            <footer className={classNames(props.light ? "bg-transparent" : "bg-stone-300", "footer  pt-1 border-b-2 border-red-900")}>
                <div className="container mx-auto px-6">

                    <div className="sm:flex sm:mt-0">
                        <div className="mt-8 sm:mt-0 sm:w-full sm:px-8 flex justify-center gap-4">
                            {/* <a className="text-black cursor-pointer">
                                <FontAwesomeIcon icon="fa-brands fa-facebook-f" />
                            </a>
                            <a className="text-black cursor-pointer">
                                <FontAwesomeIcon icon="fa-brands fa-twitter" />
                            </a>
                            <a className="text-black cursor-pointer">
                                <FontAwesomeIcon icon="fa-brands fa-google" />
                            </a>
                            <a className="text-black cursor-pointer">
                                <FontAwesomeIcon icon="fa-brands fa-instagram" />
                            </a>
                            <a className="text-black cursor-pointer">
                                <FontAwesomeIcon icon="fa-brands fa-linkedin-in" />
                            </a>
                            <a className="text-black cursor-pointer">
                                <FontAwesomeIcon icon="fa-brands fa-github" />
                            </a> */}

                        </div>
                    </div>
                </div>
                <div className="container mx-auto px-6">
                    <div className={classNames(props.light ? "" : " border-gray-300 border-t-2", "mt-0  flex justify-center")}>
                        <div className="sm:w-2/3 text-center py-6">
                            <p className=" font-sans text-sm mb-2">
                                © 2023 QRpatch
                            </p>
                        </div>
                    </div>
                </div>
            </footer>
        </div>
    )
}

export default Footer