import React from 'react';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { CalendarIcon, ChartBarIcon, FolderIcon, HomeIcon, InboxIcon, UsersIcon, CubeIcon } from '@heroicons/react/outline';
import logo from '../../../assets/company/patch.png';
import newPatch from '../../../assets/company/QR PATCH ICON ONLY.png'

import { useTranslation } from 'react-i18next';
import { useQuery } from "@tanstack/react-query";

import { getToPatchFN } from '../../../utils/request';



const secondaryNavigation = [
    { name: 'Settings', href: '#', icon: "<FontAwesomeIcon icon='fa-solid fa-cog' />" },
    { name: 'Help', href: '#', icon: "<FontAwesomeIcon icon='fa-solid fa-question' />" },
    { name: 'Privacy', href: '#', icon: "<FontAwesomeIcon icon='fa-solid fa-shield-alt' />" },
]

function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

function Sidebar(props) {

    const { t } = useTranslation()

    const clientId = localStorage.getItem('currentClientId');
    const userRole = localStorage.getItem('userRole');

    const [sidebarOpen, setSidebarOpen] = useState(false)

    const current = props.current

    const { data: toPatch } = useQuery(["toPatch"], () => getToPatchFN(clientId))

    var navigation = []

    if (userRole === "A") {
        navigation = [
            { name: t('Navigation.Sidebar.Dashboard'), icon: HomeIcon, href: './dashboard', current: true },
            { name: t('Navigation.Sidebar.Staff'), icon: UsersIcon, href: './staff', current: false },
            { name: t('Navigation.Sidebar.Manage'), icon: FolderIcon, href: './management', current: false },
            { name: t('Navigation.Sidebar.Object_Types'), icon: CubeIcon, href: './objType', current: false },
            { name: t('Navigation.Sidebar.To_Patch'), icon: CalendarIcon, href: './patch-list', count: (toPatch?.length == 0 ? null : toPatch?.length), current: false },
            //TODO Reports and Statistics
            // { name: t('Navigation.Sidebar.Reports'), icon: InboxIcon, href: './reports', current: false },
            // { name: t('Navigation.Sidebar.Statistics'), icon: ChartBarIcon, href: './statistics', current: false },
        ]
    } else {
        navigation = [
            { name: t('Navigation.Sidebar.Dashboard'), icon: HomeIcon, href: './staff-dashboard', current: true },
            { name: t('Navigation.Sidebar.To_Patch'), icon: CalendarIcon, href: './patch-list', count: (toPatch?.length == 0 ? null : toPatch?.length), current: false },
            //TODO Reports and Statistics
            // { name: t('Navigation.Sidebar.Reports'), icon: InboxIcon, href: './reports', current: false },
            // { name: t('Navigation.Sidebar.Statistics'), icon: ChartBarIcon, href: './statistics', current: false },
        ]
    }



    return (
        <div className="hidden lg:flex lg:w-64 lg:flex-col lg:fixed lg:inset-y-0">
            <div>
                <div className="flex flex-col flex-grow border-r border-gray-200 pt-5 pb-4 bg-white overflow-y-auto h-screen">
                    <div className="flex items-center flex-shrink-0 px-4">
                        <img
                            className="h-8 w-auto"
                            src={newPatch}
                            alt="QRpatch"
                        />
                    </div>
                    <div className="mt-5 flex-grow flex flex-col">
                        <nav className="flex-1 px-2 bg-white space-y-1" aria-label="Sidebar">
                            {navigation.map((item) => (
                                <a
                                    key={item.name}
                                    href={item.href}
                                    className={classNames(
                                        current === item.name ? 'bg-gray-100 text-gray-900' : 'text-gray-600 hover:bg-gray-50 hover:text-gray-900',
                                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                                    )}
                                >
                                    <item.icon
                                        className={classNames(
                                            item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                                            'mr-3 flex-shrink-0 h-6 w-6'
                                        )}
                                        aria-hidden="true"
                                    />
                                    <span className="flex grow justify-start">{item.name}</span>
                                    <div className=''>
                                        {item.count ? (
                                            <span
                                                className={classNames(
                                                    item.current ? 'bg-white' : 'bg-gray-100 group-hover:bg-gray-200 ',
                                                    'ml-3 py-0.5 flex px-3 text-xs font-medium rounded-full'
                                                )}
                                            >
                                                {item.count}
                                            </span>
                                        ) : null}
                                    </div>
                                </a>
                            ))}
                        </nav>
                    </div>
                </div>
                {/* Check if the code below is necessary */}
                <button
                    type="button"
                    className="px-4 border-r border-gray-200 text-gray-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-cyan-500 lg:hidden"
                    onClick={() => setSidebarOpen(true)}
                >
                    <FontAwesomeIcon icon="fa-solid fa-bars" className="h-6 w-6" />
                </button>
            </div>
        </div>
    )
}


export default Sidebar;