//React Imports
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

//Media Imports
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


// Query Imports
import { useQuery } from "@tanstack/react-query";
import { getCurrentUserFN, getUserClientFn, getToPatchFN } from '../../../utils/request';
import HubNavbar from '../../../components/Platform/Navigation/hubNavbar';
import NewClientModal from '../../../components/Platform/Modals/newClientModal';



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}






function Hub() {

    const { data } = useQuery(["UserClient"], getUserClientFn)

    const { t } = useTranslation();

    const navigate = useNavigate();

    const [newClient, setNewClient] = useState(false)

    const HideClientModal = (status) => {
        setNewClient(status);
    };

    const clientSetup = (userClient) => {
        localStorage.setItem('currentClientName', userClient.client.name)
        localStorage.setItem('currentClientId', userClient.client.id)
        localStorage.setItem('userRole', userClient.role)
        if (userClient.role === "A") {
            navigate(`/dashboard`)
        } else {
            navigate(`/staff-dashboard`)
        }
    }

    useEffect(() => {
        localStorage.removeItem('currentClientId');
        localStorage.removeItem('currentClientName');
    }, [])



    return (
        <div>
            <div className='overflow-x-hidden'>
                <div className='flex flex-col'>
                    <HubNavbar current={t('Navigation.Sidebar.Dashboard')} />
                    {/* Header */}
                    <div className="border-b-2 border-gray-200 flex justify-center">
                        <div className="px-4 sm:px-6 lg:max-w-6xl lg:px-8">
                            <div className="py-6 md:flex md:items-center lg:border-t lg:border-gray-200 h-40">

                                <div className="flex-1 min-w-0 text-center justify-center">
                                    <div className="flex items-center text-center justify-center">
                                        <div>
                                            <div className="flex items-center text-center justify-center">
                                                <h1 className="ml-3 text-xl md:text-3xl font-bold leading-7 text-gray-900 sm:leading-9 sm:truncate">
                                                    {t('Titles.Welcome_To_The_Hub')}
                                                </h1>
                                            </div>
                                            <dl className="mt-6 pl-2 md:pl-0 flex flex-col sm:ml-3 sm:mt-1 sm:flex-row sm:flex-wrap">
                                                <dt className="sr-only">Company</dt>
                                                <dd className="flex items-center text-md text-gray-500 font-medium sm:mr-6">

                                                    {t('Titles.Subtitles.Hub_Subtitle')}
                                                </dd>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* Main body */}
                <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4'>
                    {data?.map((userClient) => (
                        <div className='p-20 bg-slate-300 h-20 w-72 m-10 hover:cursor-pointer hover:shadow-md hover:animate-[pulse_4s_ease-in-out_infinite] ease-in-out rounded-2xl' onClick={() => clientSetup(userClient)}>
                            {userClient.client.name}
                        </div>
                    ))}
                </div>
                <div className='mt-20 lg:mt-72'>
                    <button onClick={() => setNewClient(true)} className='p-4 rounded-xl bg-green-400 hover:shadow-md mb-2 '>
                        <FontAwesomeIcon icon="fa-solid fa-plus" className='mr-2' />  {t('Buttons.Create')}
                    </button>
                </div>
            </div>
            <NewClientModal show={newClient} toggle={HideClientModal} />
        </div>
    )

}

export default Hub;