import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createUserFn } from "../../utils/request";
import { useMutation } from '@tanstack/react-query';
import { useTranslation } from "react-i18next";
import authAxios from '../../utils/APIInstance';
import { useNavigate } from 'react-router-dom';




function SignUp() {

    const { t } = useTranslation()
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const navigate = useNavigate();

    // const { mutate } = useMutation(createUserFn)

    const handleSubmit = async (e) => {
        e.preventDefault();
        const firstName = e.target.first_name.value;
        const lastName = e.target.last_name.value;
        const email = e.target.create_email.value;
        const password1 = e.target.first_password.value;
        const password2 = e.target.second_password.value;

        if (password1 === password2) {
            try {
                const response = await authAxios.post(`/auth/create/`, { first_name: firstName, last_name: lastName, email: email, password: password1, is_active: true });
                if (response.status === 201) {
                    navigate('/sign-in');
                    alert("User successfully created")
                }
            } catch (error) {
                alert(error)
            }
        } else {
            alert("passwords do not match")
        }
    };


    return (
        <div className="pl-16 w-9/12 h-screen">
            <div className="flex justify-start items-end h-[10vh]">
                <a href="/" className="cursor-pointer"><FontAwesomeIcon icon="fa-solid fa-angle-left" />   {t('Buttons.Back')}</a>
            </div>
            {/*TODO find a better alternative to overriding tailwind.*/}
            <div className="flex justify-start items-center w-full h-[90vh]">
                <div className=" w-full mt-16">
                    <h1 className="text-3xl text-gray-700 font-comfortaa text-left mb-6"> {t('Titles.Create_A_New_Account')}</h1>
                    <h1 className="text-md font-sans text-gray-700 text-left mb-12">{t('Buttons.Already_A_Member')} <a href="./sign-in" className="cursor-pointer text-cyan-600 hover:text-cyan-900 ">Login</a></h1>
                    <form className="w-full" method="post" onSubmit={handleSubmit}>
                        <div className="flex flex-col gap-5 md:grid md:grid-cols-2 md:gap-x-6 md:gap-y-10 mb-14">
                            <div className="relative z-0 text-left w-full group">
                                <input
                                    type="text"
                                    name="first_name"
                                    id="first_name"
                                    className="block py-2.5 pl-2 pr-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                                    placeholder=" "
                                    required="" />
                                <label
                                    htmlFor="first_name"
                                    className="peer-focus:font-medium absolute text-sm text-left text-gray-500 pl-2 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-user" />   {t('Inputs.First_Name')}
                                </label>
                            </div>
                            <div className="relative z-0 text-left w-full group">
                                <input
                                    type="text"
                                    name="last_name"
                                    id="last_name"
                                    className="block py-2.5 pl-2 pr-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                                    placeholder=" "
                                    required="" />
                                <label
                                    htmlFor="last_name"
                                    className="peer-focus:font-medium absolute text-sm text-left text-gray-500 pl-2 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-user" />   {t('Inputs.Last_Name')}
                                </label>
                            </div>
                            <div className=" col-span-2 relative z-0 w-full text-left group">
                                <input
                                    type="email"
                                    name="create_email"
                                    id="create_email"
                                    className="block py-2.5 pl-2 pr-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                                    placeholder=" "
                                    required="" />
                                <label
                                    htmlFor="create_email"
                                    className="peer-focus:font-medium absolute text-sm text-gray-500 pl-2 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-at" />  {t('Inputs.Email')}
                                </label>
                            </div>
                            <div className="relative z-0 text-left w-full group">
                                <input
                                    type="password"
                                    name="first_password"
                                    id="first_password"
                                    className="block py-2.5 pl-2 pr-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                                    placeholder=" "
                                    required="" />
                                <label
                                    htmlFor="first_password"
                                    className="peer-focus:font-medium absolute text-sm text-left text-gray-500 pl-2 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-lock" />   {t('Inputs.Password')}
                                </label>
                            </div>
                            <div className="relative z-0 text-left w-full group">
                                <input
                                    type="password"
                                    name="second_password"
                                    id="second_password"
                                    className="block py-2.5 pl-2 pr-0 w-full text-sm text-gray-900 bg-transparent border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-gray-600 peer"
                                    placeholder=" "
                                    required="" />
                                <label
                                    htmlFor="second_password"
                                    className="peer-focus:font-medium absolute text-sm text-left text-gray-500 pl-2 duration-300 transform -translate-y-6 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-gray-600 peer-focus:dark:text-gray-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                                >
                                    <FontAwesomeIcon icon="fa-solid fa-lock" />   {t('Inputs.Confirm_Password')}
                                </label>
                            </div>
                        </div>

                        <div className="flex justify-center">
                            <button
                                type="submit"
                                className="px-5 py-2.5 mb-10 text-sm text-stone-400 hover:text-stone-800"
                            >
                                {t('Buttons.Create')}
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default SignUp;