
import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import i18n from '../../i18n';
import US from '../../assets/imgs/flags-rectangle/us.svg';
import Brazil from '../../assets/imgs/flags-rectangle/br.svg';
import { useTranslation } from 'react-i18next';
import darkLogo from '../../assets/company/QR PATCH LOGO HORIZONTAL REVERSED.png'
import lightLogo from '../../assets/company/QR PATCH LOGO HORIZONTAL.png'



function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

const lngs = [
    { code: 'en', nativeName: 'English', flag: US },
    { code: 'pt', nativeName: 'Português', flag: Brazil },
    { code: 'fallback', nativeName: 'English', flag: US },
];

function Navbar(props) {


    let definedLanguage = false

    const [dropdown, setDropdown] = useState(-1)


    const [show, setShow] = useState(false)

    // {
    //     classNames(
    //         change >= 0 ? 'text-green-600' : 'text-red-600',
    //         'ml-2 flex items-baseline text-sm font-semibold'
    //     )
    // }

    const { t } = useTranslation()


    return (
        <nav className={classNames(props.light ? "bg-transparent" : "bg-gradient-to-r from-stone-700 to-stone-800", ' w-full px-2 sm:px-4 py-2 md:py-0 sticky top-0 left-0 z-20 bg-opacity-5 backdrop-filter backdrop-blur')}>
            <div className='md:hidden py-2 px-8'>
                <div className='absolute inset-y-0 left-0 flex items-center pl-8'>
                    <button onClick={() => setShow(!show)}>
                        <FontAwesomeIcon icon={show ? "fa-solid fa-times" : "fa-solid fa-bars"} className="text-gray-300 text-2xl  " />
                    </button>
                    <div className={show ? "z-10 absolute transform ease-in-out translate-y-1 duration-500 bg-gradient-to-r from-stone-700 to-stone-800 top-14 left-0 w-screen divide-y divide-gray-100 shadow md:hidden" : "transition ease-in-out -translate-y-3 duration-100 bg-gray-200 rounded-lg invisible h-10 "}>
                        <ul className="py-2 text-sm text-gray-400">
                            <li>
                                <a href="./" className="block px-4 py-2 hover:bg-gray-100">{t('Public.Navigation.Home')}</a>
                            </li>
                            <li>
                                <a href="./about" className="block px-4 py-2 hover:bg-gray-100">{t('Public.Navigation.About')}</a>
                            </li>
                            <li>
                                <a href="#" disabled className="block px-4 hover:cursor-not-allowed py-2 hover:bg-gray-100">{t('Public.Navigation.Pricing')}</a>
                            </li>
                            <li>
                                <div className="py-2 px-5">
                                    <a href="./sign-up" className="block justify-center items-center py-2 px-3 text-md font-medium text-center text-white bg-gradient-to-r from-red-900 to-orange-700 rounded-lg focus:ring-1 focus:outline-none focus:ring-red-900">Sign up</a>
                                </div>
                            </li>
                            <li>
                                <p className="py-3 text-gray-300">
                                    Existing customer?{' '}
                                    <a href="./sign-in" className="text-yellow-600 hover:underline pl-2">
                                        {t('Public.Navigation.Sign_In')}
                                    </a>
                                </p>
                            </li>
                        </ul>

                    </div>
                </div>
                <img src={props.light ? lightLogo : darkLogo} className='h-8 inline-block' alt='QRpatch Logo'></img>
            </div>
            <div className='hidden container md:flex flex-wrap justify-center md:justify-between items-center mx-auto'>
                <a href='https://qrpatch.com/' className='flex items-center hover:text-gray-900'>
                    <img src={props.light ? lightLogo : darkLogo} className='md:mr-3 h-8 sm:h-9' alt='QRpatch Logo'></img>
                    {/* <span className="self-center hidden md:inline-flex text-xl font-semibold whitespace-nowrap text-zinc-300">QRpatch</span> */}
                </a>
                <div className='hidden justify-between items-center w-full md:flex md:w-auto pt-2'>
                    <ul className="flex flex-col p-2 pb-3 mt-4 rounded-lg md:flex-row md:space-x-8 md:mt-0 md:text-sm md:font-medium md:border-0 md:bg-transparent">
                        <li className='my-auto'>
                            <a href="/" className={classNames(props.light ? "md:text-gray-800" : "md:text-gray-300", "block py-2 pr-4 pl-3 rounded md:bg-transparent md:hover:underline underline-offset-8 md:p-0")} aria-current="page">{t('Public.Navigation.Home')}</a>
                        </li>
                        <li className='my-auto'>
                            <a href="./about" className={classNames(props.light ? "md:text-gray-800" : "md:text-gray-300", "block py-2 pr-4 pl-3 rounded md:bg-transparent md:hover:underline underline-offset-8 md:p-0")} aria-current="page">{t('Public.Navigation.About')}</a>
                        </li>
                        <li className='my-auto'>
                            <a href="#" disabled className={classNames(props.light ? "md:text-gray-800" : "md:text-gray-300", "hover:cursor-not-allowed block py-2 pr-4 pl-3 rounded md:bg-transparent md:hover:underline underline-offset-8 md:p-0")}>{t('Public.Navigation.Pricing')}</a>
                        </li>
                        <li className='my-auto'>
                            {lngs.map((lng) => {
                                if (!definedLanguage && (lng.code == i18n.language || lng.code === 'fallback')) {
                                    definedLanguage = true;
                                    return (
                                        <a onClick={() => setDropdown((dropdown !== 1) ? 1 : -1)} className={classNames(props.light ? "md:text-gray-800" : "md:text-gray-300 hover:bg-gray-100 md:hover:bg-transparent md:hover:text-gray-300", "flex py-2 pr-4 pl- rounded  md:p-0 hover:cursor-pointer")}><img src={lng.flag} className='h-4 pr-2 mt-0.5'></img> {lng.nativeName} <FontAwesomeIcon icon="fa-solid fa-angle-down" className='pl-1 mt-1' /></a>
                                    )
                                }
                            })}
                            {/* <!-- Language Dropdown menu --> */}
                            <div className={dropdown === 1 ? "z-10 absolute bg-white mt-6 mr-24 md:mr-96 md:-translate-x-10 divide-y divide-gray-100 rounded-lg shadow w-44" : "z-10 hidden bg-white mt-44 mr-64 divide-y divide-gray-100 rounded-lg shadow w-44"}>
                                <ul className="py-2 text-sm text-gray-700 dark:text-gray-200">
                                    {lngs.map((lng) => {
                                        if (lng.code !== 'fallback') {
                                            return (
                                                <li className='hover:cursor-pointer'>
                                                    <a onClick={() => (i18n.changeLanguage(lng.code), setDropdown((dropdown != 1) ? 1 : -1), localStorage.setItem('language', lng.code))} key={lng.code} className="block px-6 py-2 hover:bg-gray-100"><img src={lng.flag} className=' inline-block h-4 pr-2 mb-1'></img>{(lng.code.toUpperCase())}</a>
                                                </li>
                                            )
                                        }
                                    })}

                                </ul>
                            </div>
                        </li>
                        <li >
                            <a href="./sign-in" className={classNames(props.light ? "md:text-gray-800" : "md:text-gray-300", "block py-2 lg:ml-10 text-md font-medium text-centerrounded-lg ")}>{t('Public.Navigation.Sign_In')}</a>
                        </li>
                        <li >
                            <a href="./sign-up" className="block justify-center items-center py-2 px-3 text-md font-medium text-center text-white bg-gradient-to-r from-red-900 to-orange-700 rounded-lg focus:ring-1 focus:outline-none focus:ring-red-900">{t('Public.Navigation.Sign_Up')}</a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    )
}

export default Navbar;