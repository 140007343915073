import React, { useState } from 'react';
import Sidebar from "../../../components/Platform/Navigation/sidebar";
import { useTranslation } from "react-i18next";
import Navbar from "../../../components/Platform/Navigation/navbar";
import User from "../../../assets/imgs/user.svg"
import { useMutation, useQueryClient, useQuery } from "@tanstack/react-query";
import { updateUserAvatarFn, getCurrentUserFN } from "../../../utils/request";



const secondaryNavigation = [
    { name: 'Account', href: '#', current: true },
    { name: 'Settings', href: '#', current: false },
]


function Profile() {

    const [selectedFile, setSelectedFile] = useState(null);

    const { t } = useTranslation()

    const queryClient = useQueryClient();

    const { data: user } = useQuery(["User"], getCurrentUserFN)

    const { mutate } = useMutation(updateUserAvatarFn, {
        onSuccess: () => {
            queryClient.invalidateQueries(["User"])
        },
    })

    const handleAvatarChange = (e) => {
        const file = e.target.files[0];
        setSelectedFile(file);
    }

    const handleSave = () => {
        if (selectedFile) {
            mutate(selectedFile);
        }
    }


    return (
        <div >
            <Sidebar current={t('Navigation.Sidebar.Manage')} />
            <div className="lg:hidden">
                <Navbar current={t('Navigation.Sidebar.Manage')} className="hidden" />
            </div>
            <div className="h-full bg-stone-100 lg:ml-64 pt-10 px-8 flex-grow">
                <header className="border-b border-stone-300">
                    {/* Secondary navigation */}
                    {console.log(user?.photo_file)}
                    <nav className="flex overflow-x-auto py-4">
                        <ul
                            role="list"
                            className="flex min-w-full flex-none gap-x-6 px-4 text-sm font-semibold leading-6 text-gray-800 sm:px-6 lg:px-8"
                        >
                            {secondaryNavigation.map((item) => (
                                <li key={item.name}>
                                    <a href={item.href} className={item.current ? 'text-red-800' : ''}>
                                        {item.name}
                                    </a>
                                </li>
                            ))}
                        </ul>
                    </nav>
                </header>
                <div className="divide-y divide-stone-300">
                    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                        <div>
                            <h2 className="flex text-base font-semibold leading-7 text-black">Personal Information</h2>
                            <p className="flex text-justify mt-1 text-sm leading-6 text-gray-800">
                                Use a permanent address where you can receive mail.
                            </p>
                        </div>

                        <form className="md:col-span-2">
                            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                                <div className="col-span-full flex items-center gap-x-8">
                                    <img
                                        src={user?.photo_file ? user.photo_file : User}
                                        alt=""
                                        className="h-24 w-24  rounded-xl"
                                    />
                                    <div>
                                        <div className="flex-col text-start">
                                            <input
                                                type="file"
                                                id="avatar"
                                                name="avatar"
                                                accept="image/png, image/jpeg"
                                                onChange={handleAvatarChange}
                                            />
                                            {/* <button
                                            htmlFor="avatar"
                                            type="file"
                                            className="rounded-md bg-stone-200 px-3 py-2 text-sm font-semibold text-black shadow-sm hover:bg-stone-400"
                                        >
                                            Change avatar
                                        </button> */}
                                            <p className="mt-2 text-xs leading-5 text-gray-800">JPG, GIF or PNG. 1MB max.</p>
                                        </div>

                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="first-name" className="block text-sm font-medium leading-6 text-black">
                                        First name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="first-name"
                                            id="first-name"
                                            autoComplete="given-name"
                                            className="block w-full rounded-md border-0 bg-white py-1.5 text-black shadow-sm ring-1 ring-inset ring-stone-200 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div className="sm:col-span-3">
                                    <label htmlFor="last-name" className="block text-sm font-medium leading-6 text-black">
                                        Last name
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            type="text"
                                            name="last-name"
                                            id="last-name"
                                            autoComplete="family-name"
                                            className="block w-full rounded-md border-0 bg-white py-1.5 text-black shadow-sm ring-1 ring-inset ring-stone-200 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="email" className="block text-sm font-medium leading-6 text-black">
                                        Email address
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            autoComplete="email"
                                            className="block w-full rounded-md border-0 bg-white py-1.5 text-black shadow-sm ring-1 ring-inset ring-stone-200 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-8 flex justify-end lg:justify-start">
                                <button
                                    type="submit"
                                    className="rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-800"
                                    onClick={handleSave}
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>

                    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                        <div>
                            <h2 className="flex text-base font-semibold leading-7 text-black">Change password</h2>
                            <p className="flex text-justify mt-1 text-sm leading-6 text-gray-800">
                                Update your password associated with your account.
                            </p>
                        </div>

                        <form className="md:col-span-2">
                            <div className="grid grid-cols-1 gap-x-6 gap-y-8 sm:max-w-xl sm:grid-cols-6">
                                <div className="col-span-full">
                                    <label htmlFor="current-password" className="block text-sm font-medium leading-6 text-black">
                                        Current password
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="current-password"
                                            name="current_password"
                                            type="password"
                                            autoComplete="current-password"
                                            className="block w-full rounded-md border-0 bg-white py-1.5 text-black shadow-sm ring-1 ring-inset ring-stone-200 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="new-password" className="block text-sm font-medium leading-6 text-black">
                                        New password
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="new-password"
                                            name="new_password"
                                            type="password"
                                            autoComplete="new-password"
                                            className="block w-full rounded-md border-0 bg-white py-1.5 text-black shadow-sm ring-1 ring-inset ring-stone-200 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>

                                <div className="col-span-full">
                                    <label htmlFor="confirm-password" className="block text-sm font-medium leading-6 text-black">
                                        Confirm password
                                    </label>
                                    <div className="mt-2">
                                        <input
                                            id="confirm-password"
                                            name="confirm_password"
                                            type="password"
                                            autoComplete="new-password"
                                            className="block w-full rounded-md border-0 bg-white py-1.5 text-black shadow-sm ring-1 ring-inset ring-stone-200 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="mt-8 flex">
                                <button
                                    type="submit"
                                    className="rounded-md bg-red-800 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-800"
                                >
                                    Save
                                </button>
                            </div>
                        </form>
                    </div>
                    <div className="grid max-w-7xl grid-cols-1 gap-x-8 gap-y-10 px-4 py-16 sm:px-6 md:grid-cols-3 lg:px-8">
                        <div>
                            <h2 className="flex text-base font-semibold leading-7 text-black">Delete account</h2>
                            <p className="flex mt-1 text-sm leading-6 text-justify text-gray-800">
                                No longer want to use our service? You can delete your account here. This action is not reversible.
                                All information related to this account will be deleted permanently.
                            </p>
                        </div>

                        <form className="flex items-start md:col-span-2">
                            <button
                                type="submit"
                                className="rounded-md bg-red-500 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-400"
                            >
                                Yes, delete my account
                            </button>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Profile;