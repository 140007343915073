import { useState } from 'react'
import { Dialog } from '@headlessui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Navbar from '../../components/Public/navbar'
import Footer from '../../components/Public/footer'
import Dashboard from '../../assets/imgs/Dashboard.png'
import fixing from '../../assets/imgs/fixing.jpg'
import code from '../../assets/imgs/code.jpg'
import rocket from '../../assets/imgs/rocket.svg'
import formal from '../../assets/imgs/formal profile.jpg'
import { useTranslation } from 'react-i18next'
import saveMoney from '../../assets/imgs/Save Money.png'
import saveTime from '../../assets/imgs/Save Time.png'
import customerSatisfaction from '../../assets/imgs/Customer Satisfaction.png'
import banner from '../../assets/imgs/scanning qrpatch.png'




function classNames(...classes) {
    return classes.filter(Boolean).join(' ')
}

export default function About() {
    const { t } = useTranslation()

    const values = [
        {
            name: `${t('Public.About.Values.Efficiency')}`,
            description:
                `${t('Public.About.Values.Efficiency_Text')}`,
        },
        {
            name: `${t('Public.About.Values.Innovation')}`,
            description:
                `${t('Public.About.Values.Innovation_Text')}`,
        },
        {
            name: `${t('Public.About.Values.Customer_Satisfaction')}`,
            description:
                `${t('Public.About.Values.Customer_Satisfaction_Text')}`,
        },
        {
            name: `${t('Public.About.Values.Sustainability')}`,
            description:
                `${t('Public.About.Values.Sustainability_Text')}`,
        },
        {
            name: `${t('Public.About.Values.Transparency')}`,
            description:
                'Our platform’s reports and insights give you a clear picture of how things are running.',
        },
        {
            name: `${t('Public.About.Values.Integrity')}`,
            description:
                `${t('Public.About.Values.Integrity_Text')}`,
        },
    ]

    const [benefit, setBenefit] = useState(0);


    return (
        <div className="bg-white">
            <Navbar light={true} />
            <main className="isolate">
                {/* Hero section */}
                <div className="relative isolate -z-10">
                    <svg
                        className="absolute inset-x-0 top-0 -z-10 h-[64rem] w-full stroke-gray-200 [mask-image:radial-gradient(32rem_32rem_at_center,white,transparent)]"
                        aria-hidden="true"
                    >
                        <defs>
                            <pattern
                                id="1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84"
                                width={200}
                                height={200}
                                x="50%"
                                y={-1}
                                patternUnits="userSpaceOnUse"
                            >
                                <path d="M.5 200V.5H200" fill="none" />
                            </pattern>
                        </defs>
                        <svg x="50%" y={-1} className="overflow-visible fill-gray-50">
                            <path
                                d="M-200 0h201v201h-201Z M600 0h201v201h-201Z M-400 600h201v201h-201Z M200 800h201v201h-201Z"
                                strokeWidth={0}
                            />
                        </svg>
                        <rect width="100%" height="100%" strokeWidth={0} fill="url(#1f932ae7-37de-4c0a-a8b0-a6e3b4d44b84)" />
                    </svg>
                    <div
                        className="absolute left-1/2 right-0 top-0 -z-10 -ml-24 transform-gpu overflow-hidden blur-3xl lg:ml-24 xl:ml-48"
                        aria-hidden="true"
                    >
                        <div
                            className="aspect-[801/1036] w-[50.0625rem] bg-gradient-to-tr from-[#858585] to-[#636262] opacity-30"
                            style={{
                                clipPath:
                                    'polygon(63.1% 29.5%, 100% 17.1%, 76.6% 3%, 48.4% 0%, 44.6% 4.7%, 54.5% 25.3%, 59.8% 49%, 55.2% 57.8%, 44.4% 57.2%, 27.8% 47.9%, 35.1% 81.5%, 0% 97.7%, 39.2% 100%, 35.2% 81.4%, 97.2% 52.8%, 63.1% 29.5%)',
                            }}
                        />
                    </div>
                    <div className="overflow-hidden">
                        <div className="mx-auto max-w-7xl px-6 pb-32 pt-20 sm:pt-32 lg:px-8 lg:pt-10">
                            <div className="mx-auto max-w-2xl gap-x-14 lg:mx-0 lg:flex lg:max-w-none lg:items-center">
                                <div className="w-full max-w-xl md:ml-10 lg:shrink-0 xl:max-w-2xl">
                                    <h1 className="text-4xl font-bold tracking-tight text-gray-900 sm:text-6xl">
                                        {t('Public.About.Header')}
                                    </h1>
                                    <p className="relative mt-6 md:ml-16 text-lg leading-8 text-gray-600 sm:max-w-md lg:max-w-none">
                                        {t('Public.About.SubHeader')}

                                    </p>
                                </div>
                                <div className="mt-14 md:mt-4 flex justify-end gap-8 sm:-mt-44 sm:justify-start sm:pl-20 lg:mt-0 lg:pl-0">
                                    <div>
                                        <img className='xl:max-w-2xl' src={banner}></img>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Content section */}
                <div className="mx-auto -mt-12 max-w-7xl px-6 sm:mt-0 lg:px-8 xl:-mt-8">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none text-left">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t('Public.About.Our_Mission')}</h2>
                        <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row">
                            <div className="lg:w-full lg:max-w-2xl lg:flex-auto">
                                <p className="text-xl leading-8 text-gray-600">
                                    {t('Public.About.text1')}
                                </p>
                                <div className="mt-10 max-w-xl text-base leading-7 text-gray-700">
                                    <p>
                                        {t('Public.About.text2')}
                                    </p>
                                    <p className="mt-10">
                                        {t('Public.About.text3')}
                                    </p>
                                </div>
                            </div>
                            <div className="flex justify-center lg:flex lg:flex-auto lg:justify-center">
                                <img className="w-64 space-y-8 xl:w-80 aspect-auto" src={rocket}>
                                </img>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Content section */}
                <div className="mt-32 px-6 sm:mt-24 lg:mx-auto lg:max-w-7xl lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none text-left">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl"> {t('Public.About.Founder.Meet')}</h2>
                        <div className="mt-6 flex flex-col gap-x-8 gap-y-20 lg:flex-row justify-center">
                            <div class="items-center lg:hidden grid grid-cols-2 bg-gray-50 rounded-lg shadow mt-10 p-5">
                                <a href="#">
                                    <img className="rounded-lg sm:rounded-none sm:rounded-l-lg lg:h-52 lg:w-80" src={formal} alt="Alexandre Marques" />
                                </a>
                                <div className="p-5">
                                    <h3 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                                        <a href="#">Alexandre Marques</a>
                                    </h3>
                                    <span className="text-gray-500 dark:text-gray-400"> {t('Public.About.Founder.Position')}</span>
                                </div>

                                <p className="lg:mt-3 lg:px-5 mt-6 mb-4 font-light text-gray-500 dark:text-gray-400 col-span-2 "> {t('Public.About.Founder.Description')}</p>
                                <div className="sm:flex sm:mt-8 col-span-2">
                                    <div className="mt-8 sm:mt-0 sm:w-full sm:px-8 flex justify-center gap-4">

                                        <a href='https://www.instagram.com/alexandre.marquess/' className="text-black cursor-pointer">
                                            <FontAwesomeIcon icon="fa-brands fa-instagram" />
                                        </a>
                                        {/* <a href='https://www.linkedin.com/in/alexandre-marques-3293bb245/' className="text-black cursor-pointer">
                                                <FontAwesomeIcon icon="fa-brands fa-linkedin-in" />
                                            </a> */}

                                    </div>
                                </div>

                            </div>
                            <div class="hidden items-center bg-gray-50 rounded-lg shadow lg:flex mt-10 p-5 lg:p-0">
                                <a href="#">
                                    <img className="rounded-lg sm:rounded-none sm:rounded-l-lg lg:h-52 lg:w-80" src={formal} alt="Alexandre Marques" />
                                </a>
                                <div className='lg:block '>
                                    <div className="p-5">
                                        <h3 className="text-xl font-bold tracking-tight text-gray-900 dark:text-white">
                                            <a href="#">Alexandre Marques</a>
                                        </h3>
                                        <span className="text-gray-500 dark:text-gray-400"> {t('Public.About.Founder.Position')}</span>
                                    </div>

                                    <p className="lg:mt-3 lg:px-5 mt-6 mb-4 font-light text-gray-500 dark:text-gray-400 col-span-2 "> {t('Public.About.Founder.Description')}</p>
                                    <div className="sm:flex sm:mt-8 col-span-2">
                                        <div className="mt-8 sm:mt-0 sm:w-full sm:px-8 flex justify-center gap-4">

                                            <a href='https://www.instagram.com/alexandre.marquess/' className="text-black cursor-pointer">
                                                <FontAwesomeIcon icon="fa-brands fa-instagram" />
                                            </a>
                                            {/* <a href='https://www.linkedin.com/in/alexandre-marques-3293bb245/' className="text-black cursor-pointer">
                                                <FontAwesomeIcon icon="fa-brands fa-linkedin-in" />
                                            </a> */}

                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-32 px-6 sm:mt-40 lg:mx-auto lg:max-w-7xl lg:px-8">
                    <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-none text-left">
                        <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t('Public.About.Benefits.Why_QRpatch')}</h2>
                        <div className='flex flex-col mt-16 lg:mt-24 gap-10'>
                            <div className='flex flex-col lg:grid lg:grid-cols-3 border-b-2 pb-8 lg:px-10'>
                                <div className='col-span-1 order-2 lg:order-1 mt-6 lg:mt-0'>
                                    <img src={saveTime} className='rounded-xl'></img>
                                </div>
                                <div className='col-span-2 lg:ml-20 text-left order-1 lg:order-2'>
                                    <p className='text-2xl font-semibold '>
                                        {t('Public.About.Benefits.Save_Time')}
                                    </p>
                                    <p className="mt-8 ">
                                        {t('Public.About.Benefits.Save_Time_Text')}
                                    </p>
                                </div>
                            </div>
                            <div className='flex flex-col lg:grid lg:grid-cols-3 border-b-2 pb-8 lg:px-10'>
                                <div className='col-span-1 order-2 lg:order-1 mt-6 lg:mt-0'>
                                    <img src={saveMoney} className='rounded-xl'></img>
                                </div>
                                <div className='col-span-2 lg:ml-20 text-left order-1 lg:order-2'>
                                    <p className='text-2xl font-semibold '>
                                        {t('Public.About.Benefits.Save_Money')}
                                    </p>
                                    <p className="mt-8 ">
                                        {t('Public.About.Benefits.Save_Money_Text')}
                                    </p>
                                </div>
                            </div>
                            <div className='flex flex-col lg:grid lg:grid-cols-3 border-b-2 pb-8 lg:px-10'>
                                <div className='col-span-1 order-2 lg:order-1 mt-6 lg:mt-0'>
                                    <img src={customerSatisfaction} className='rounded-xl'></img>
                                </div>
                                <div className='col-span-2 lg:ml-20 text-left order-1 lg:order-2'>
                                    <p className='text-2xl font-semibold '>
                                        {t('Public.About.Benefits.Keep_Customers_Satisfied')}
                                    </p>
                                    <p className="mt-8 ">
                                        {t('Public.About.Benefits.Keep_Customers_Satisfied_Text')}
                                    </p>
                                </div>
                            </div>


                        </div>
                        <div className="relative overflow-hidden pt-16 lg:pt-20">
                            <div className="mx-auto max-w-7xl px-6 lg:px-8">
                                <img
                                    className="mb-[-6%] rounded-xl shadow-2xl ring-1 ring-gray-900/10"
                                    src={Dashboard}
                                    alt=""
                                />
                                <div className="relative" aria-hidden="true">
                                    <div className="absolute -inset-x-20 bottom-0 bg-gradient-to-t from-white pt-[7%]" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-20 flex lg:justify-start justify-center">
                        <a
                            href="#"
                            className="block justify-center items-center py-2 px-3 text-md font-medium text-center text-white bg-gradient-to-r from-red-900 to-orange-700 rounded-lg focus:ring-1 focus:outline-none focus:ring-red-900"
                        >
                            {t('Buttons.Get_Started_Now')}
                        </a>
                    </div>
                </div>

                <div className="relative isolate -z-10 mt-32 sm:mt-32 mb-44">
                    <div className="absolute inset-x-0 top-1/2 -z-10 flex -translate-y-64 justify-center overflow-hidden [mask-image:radial-gradient(50%_45%_at_50%_55%,white,transparent)]">
                        <svg className="h-[40rem] w-[80rem] flex-none stroke-gray-200" aria-hidden="true">
                            <defs>
                                <pattern
                                    id="e9033f3e-f665-41a6-84ef-756f6778e6fe"
                                    width={200}
                                    height={200}
                                    x="50%"
                                    y="50%"
                                    patternUnits="userSpaceOnUse"
                                    patternTransform="translate(-100 0)"
                                >
                                    <path d="M.5 200V.5H200" fill="none" />
                                </pattern>
                            </defs>
                            <svg x="50%" y="50%" className="overflow-visible fill-gray-50">
                                <path d="M-300 0h201v201h-201Z M300 200h201v201h-201Z" strokeWidth={0} />
                            </svg>
                            <rect width="100%" height="100%" strokeWidth={0} fill="url(#e9033f3e-f665-41a6-84ef-756f6778e6fe)" />
                        </svg>
                    </div>

                    {/* Values section */}
                    <div className="mx-auto mt-32 max-w-7xl px-6 sm:mt-0 lg:px-8 text-left">
                        <div className="mx-auto max-w-2xl lg:mx-0">
                            <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">{t('Public.About.Values.Title')}</h2>
                        </div>
                        <dl className="mx-auto mt-16 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 text-base leading-7 sm:grid-cols-2 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                            {values.map((value) => (
                                <div key={value.name}>
                                    <dt className="font-semibold text-gray-900">{value.name}</dt>
                                    <dd className="mt-1 text-gray-600">{value.description}</dd>
                                </div>
                            ))}
                        </dl>
                    </div>
                </div>



            </main>

            {/* Footer */}
            <Footer light={true} />
        </div>
    )
}