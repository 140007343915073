import { Fragment, useState, useEffect } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { createClientFn } from '../../../utils/request';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// 

function NewClientModal(props) {

    const { t } = useTranslation()

    const navigate = useNavigate()

    const [open, setOpen] = useState(props.show)
    const [clientName, setClientName] = useState("")

    const queryClient = useQueryClient();

    const { mutate } = useMutation(createClientFn, {
        onSuccess: () => {
            queryClient.invalidateQueries(["UserClient"])
        },
    })

    useEffect(() => {
        setOpen(props.show);
    }, [props]);

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative z-40" onClose={() => (setOpen(false), props.toggle(false))}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                </Transition.Child>

                <div className="fixed inset-0 z-40 overflow-y-auto">
                    <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pt-5 pb-4 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                                <div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                                    <button
                                        type="button"
                                        className="rounded-md bg-white text-gray-400 hover:text-gray-500 "
                                        onClick={() => (setOpen(false), props.toggle(false))}
                                    >
                                        <span className="sr-only">Close</span>
                                        <FontAwesomeIcon icon="fa-solid fa-times" className="h-6 w-6" />
                                    </button>
                                </div>
                                <div className="sm:flex sm:items-start">
                                    <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-amber-100 sm:mx-0 sm:h-10 sm:w-10">
                                        <FontAwesomeIcon icon="fa-solid fa-building" className="h-6 w-6 text-amber-400" />
                                    </div>
                                    <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left w-full">
                                        <Dialog.Title as="h3" className="text-lg font-medium leading-6 text-gray-900 pt-2">
                                            {t('Titles.Create_A_New_Company')}
                                        </Dialog.Title>
                                        <div className="mt-9 w-full">
                                            <div class="mb-8 md:pr-7 w-full">
                                                <label for="clientName" className="block w-full mb-2 text-sm font-medium text-gray-900 "> {t('Inputs.Name')}</label>
                                                <input type="text" id="clientName" value={clientName} className="w-full bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5" onChange={(e) => setClientName(e.target.value)} required />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                                    <button
                                        type="button"
                                        className="inline-flex w-full justify-center rounded-md border border-transparent bg-amber-400 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-amber-500 sm:ml-3 sm:w-auto sm:text-sm"
                                        onClick={() => (mutate({ name: clientName }), setOpen(false), props.toggle(false), navigate("/dashboard"), console.log(clientName))}
                                        disabled={clientName !== "" ? false : true}
                                    >
                                        {t('Buttons.Create')}
                                    </button>
                                    <button
                                        type="button"
                                        className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:text-gray-500 sm:mt-0 sm:w-auto sm:text-sm"
                                        onClick={() => (setOpen(false), props.toggle(false))}
                                    >
                                        {t('Buttons.Cancel')}
                                    </button>
                                </div>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    )
}

export default NewClientModal;;