import axios from 'axios';

var baseURL = "http://127.0.0.1:8001/bff";
if (process.env.REACT_APP_API_URL !== undefined) {
    baseURL = process.env.REACT_APP_API_URL;
}

const authAxios = axios.create({

    baseURL: baseURL,
    withCredentials: true,
    headers: {
        'Content-Type': 'application/json',
        'X-Requested-With': 'XMLHttpRequest',
    },
});

authAxios.interceptors.response.use(
    response => response,
    error => {
        const status = error.response ? error.response.status : null;
        const isTokenRefreshFailed = error.response && error.response.data && error.response.data.token_refresh_failed;

        if ((status === 401 && isTokenRefreshFailed) || status === 403) {
            window.location = '/sign-in';
        }

        return Promise.reject(error);
    }
);

export default authAxios

// import axios from 'axios';

// var baseURL = "http://127.0.0.1:8001/bff";
// if (process.env.REACT_APP_API_URL !== undefined) {
//     baseURL = process.env.REACT_APP_API_URL;
// }

// const authAxios = axios.create({
//     baseURL: baseURL,
//     withCredentials: true,
//     headers: {
//         'Content-Type': 'application/json',
//         'X-Requested-With': 'XMLHttpRequest',
//     },
// });

// authAxios.interceptors.response.use(
//     response => response,
//     error => {
//         const originalRequest = error.config;
//         const status = error.response ? error.response.status : null;

//         // If the error is 401 Unauthorized and we haven't already tried to refresh the token, try to refresh it
//         if (status === 401 && !originalRequest._retry) {
//             originalRequest._retry = true;
//             return authAxios.post('/auth/refresh_token/')
//                 .then(response => {
//                     if (response.status === 200) {
//                         // If refreshing the token succeeded, retry the original request
//                         originalRequest.headers['Authorization'] = 'Bearer ' + response.data.token;
//                         return authAxios(originalRequest);
//                     } else {
//                         throw new Error('Failed to refresh token');
//                     }
//                 });
//         }

//         // If the error is 403 Forbidden or the token refresh failed, redirect to the login page
//         if (status === 403 || error.message === 'Failed to refresh token') {
//             window.location = '/sign-in';
//         }

//         return Promise.reject(error);
//     }
// );




//!Above this


// export default authAxios;

// export const refreshAccessToken = async () => {
//     try {
//         const Tokens = JSON.parse(localStorage.getItem('authTokens'));
//         const response = await authAxios.post("/token/refresh/", { refresh: Tokens?.refresh })
//         Tokens.access = response.data.access
//         { response.status === 200 ? localStorage.setItem('authTokens', JSON.stringify(Tokens)) : console.log("Response Status: " + response.status) }



//     } catch (error) {
//         console.log(error.response.data)
//     }

// }


// authAxios.defaults.headers.common['Authorization'] = `Bearer ${(JSON.parse(localStorage.getItem('authTokens'))).access}`;
//console.log("Compare Access Token: " + (JSON.parse(localStorage.getItem('authTokens'))).access);

// export const refreshAccessToken = async () => {

// }






// export default function DoApiRequest(path) {

//     const fetchData = async () => {
//         try {
//             const result = await authAxios.get(`/${path}`);
//             return result.data
//         } catch (error) {
//             console.log(error.message);
//         }
//     }

//     return fetchData();
// }

// export default const authAxios = axios.create({
//     baseURL: "http://127.0.0.1:8000/api",
//     headers: {
//         'Content-Type': 'application/json',
//         'Authorization': `Bearer ${(JSON.parse(localStorage.getItem('authTokens'))).access}`,
//     },
// });

