import QRScanner from "../../../components/Platform/Data/QRcodes/qrScanner";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";


function CameraPatch() {
    const { t } = useTranslation()

    return (
        <div>
            <div className="flex justify-start items-end h-[8vh] md:ml-32 ml-4">
                <a href="./patch-list" className="cursor-pointer"><FontAwesomeIcon icon="fa-solid fa-angle-left" />   {t('Buttons.Back')}</a>
            </div>
            <h1 className="font-comfortaa text-xl my-12 ">{t('Text.Align_the_QRpatch_With_The_Camera_Below')}</h1>
            <QRScanner />
        </div>
    )
}

export default CameraPatch;